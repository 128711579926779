<template>
    <pop-up id="mobile-options-menu" class="mobile-options-menu">
        <ul class="px-0 list-unstyled mb-0">
            <li class="option d-flex py-2 size-14 cursor-pointer" v-for="{label, icon, size} in options" :key="label" @click="$emit(label.split('.')[1])">
                <div class="me-2" style="width: 20px;">
                    <component :is='icon' :size="size" color="var(--gray-1001)"/>
                </div>
                <span>
                    {{ $t(label) }}
                </span>
            </li>
        </ul>
    </pop-up>
</template>
<script>
import PopUp from '../../../../common/components/popup/Popup'
export default {
    name: 'MobileOptionsMenu',
    components: { PopUp },
    props: {
        options: { type: Array },
    },
    data() {
        return {
            listenerTimeout: null,
        }
    },
    methods: {
        handleOutsideNoteClick(evt) {
            const el = document.getElementById('mobile-options-menu');
            if (!el || !(el == evt.target || el.contains(evt.target))) this.$emit('hide');
        },
    },
    created() {
        this.listenerTimeout = setTimeout(() => {
            document.body.addEventListener('click', this.handleOutsideNoteClick);
        }, 100);
    },
    destroyed() {
        document.body.removeEventListener('click', this.handleOutsideNoteClick);
        clearTimeout(this.listenerTimeout);
    },
};
</script>

<style scoped>
.mobile-options-menu {
    min-width: 175px;
    z-index: 7;
    border-radius: 7px;
    overflow: hidden;
}
.mobile-options-menu::before {
    display: none;
}
.mobile-options-menu li.option {
    padding-inline: 12px;
    transition: var(--transition-1);
}
.mobile-options-menu li.option:hover, .mobile-options-menu li.option:active {
    background-color: var(--light-gray-100);
}
.mobile-options-menu ul li.option:first-child {
    padding-top: 8px !important;
}
.mobile-options-menu ul li.option:last-child {
    padding-bottom: 8px !important;
}
</style>