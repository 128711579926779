<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" :confirm-title="'cart.delete_selection'" description="cart.are_you_sure_you_want_to_remove_these_products">
        <icon-delete :size="100"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import IconDelete from '../../assets/svg/iconDelete.vue';

export default {
    name: "ConfirmDeleteSelection",
    components: {
        MainConfirm,
        IconDelete
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        }
    }
}
</script>
