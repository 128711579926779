<template>
    <div class="container-product-short d-sm-flex">
        <div class="d-flex flex-grow-1">
            <div class="position-relative">
                <ribbon v-if="product.special_price" />
            </div>
            <div class="favourite-photo-container position-relative">
                    <swiper class="container-img-product" :ref="`product-${product.codarticulo}`" @slideChange="onSlideChange(`product-${product.codarticulo}`)">
                        <swiper-slide v-for="(img, i) in product.imageGallery" :key="`product-${product.code}-${i}`">
                            <div v-if="shouldRenderItem(i)">
                                <div class="dark-overlay"></div>
                                <!-- <router-link v-if="mobile" :to="disabledClick">
                                    <image-product :styleObject="{'object-fit': entityValidation? 'contain' : 'cover'}" :source="img.picture_url" class="img-product-details"/>
                                </router-link> -->
                                <image-product :styleObject="{'object-fit': entityValidation? 'contain' : 'cover'}" :source="img.picture_url" class="img-product-details"/>
                                <div @click="showQuickView({product, value: true})" class="icon-eye d-none">
                                    <icon-eye :size="30" color="white"/>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                <icon-favourite v-if="viewPort('sm', {max: true})" class="position-absolute ms-auto me-1" :size="20" @click.native="addToWishList(product)" :class="[checkInFavorites(product) ? 'fill-light-main-red' : 'fill-gray-1001']"/>
            </div>
            <div class="d-flex flex-column flex-grow-1">
                <div class="product-title d-flex" data-toggle="tooltip" data-placement="top" :title="product.description | textFormat">
                    <div class="detail-description d-flex flex-column">
                        <router-link :to="disabledClick" class="product-description mb-0">
                            {{ product.description | textFormat }}
                        </router-link>
                        <div class="rating-info d-flex position-relative cursor-pointer mt-2 mt-sm-0 mb-sm-2 pb-sm-1" @click="showRatingFunction(true)" @mouseleave="showRatingFunction(false)" >
                            <star-rating :showRating="false" readOnly :starSize="viewPort('sm') ? 18:21" class="me-3" :rating="product.avgRating" :totalReviews="product.total_reviews" :product_id="product._id" />
                            <div v-if="showRating && false" class="show-rating bg-white">
                                <rating-menu class="p-3" alwaysShow :rating="product.avgRating" :allRated="product.reviews" :producto="product" :codarticulo="product._id"/>
                            </div>
                            <span class="d-none d-md-flex color-dark-gray-200 size-14" style="text-transform: lowercase;">
                                ({{product.total_reviews}}) {{$t("product.reviews")}}
                            </span>
                        </div>
                    </div>
                    <div class="dots-container d-flex d-xl-none justify-content-center align-content-center align-items-center ms-auto position-relative" 
                    :class="{'hovered': mobileOptions.id === product._id}" style="z-index: 1;">
                        <icon-dot-menu @click.native="mobileOptions.id = product._id" class="cursor-pointer" :size="25" color="var(--gray-1001)"/>
                        <mobile-options-menu v-if="mobileOptions.id === product._id" style="top: 0px; left: -138px;" @hide="mobileOptions.id = ''" :options="options"
                        @delete="() => {$emit('deleteConfirm', product)}" @share="() => {$emit('showShare', {_id: this.product._id})}" @wishlist_add="addToWishList(product)" />
                    </div>
                </div>
                <span style="margin-top: -7px;" v-if="entityValidation && product.classification.activated == true"  class="d-none d-sm-block subtitle-14">{{$t('product.clasification')}}: <span :style="{ backgroundColor: product.classification.bg_color, color: product.classification.color , padding : '4px 8px 4px 8px'}" class="badge custom-badge ms-1">{{product.classification.description | textFormat}}</span></span>
                <router-link :to="disabledClick" class="d-none d-sm-block subtitle-14" 
                :style="{'margin-top': `${product.special_price ? 0:6}px`}">
                <span>{{$t('product.code')}}:</span>  <span class="color-dark-gray-300">{{ product.codarticulo }}</span>
            </router-link>
            <h6 class="d-none d-sm-block subtitle-14" v-if="entityValidation"><span>{{$t('product.existence')}}:</span> <span class="color-dark-gray-300">({{ product.existence }})</span></h6>
                <h6 v-if="!product.special_price" class="subtitle-14 mb-0">
                    <span v-if="viewPort('sm')">
                        {{$t("product.price")}}: 
                    </span>
                    <b v-if="viewPort('sm')" class="ms-1 size-17">
                        <span class="color-dark-main-navy" style="vertical-align: top;">
                            {{showValueMoney()}}$ 
                        </span>
                        {{getPriceFromCart(product) | moneyFormat(0, false)}}
                    </b> 
                </h6>
                <div v-else-if="product.special_price && viewPort('sm')" class="d-flex flex-column">
                    <h6 class="size-14 mb-0">
                        {{$t("product.price")}}: 
                        <span class="special-price size-16 color-dark-gray-400 ms-1 me-2">{{product.price | moneyFormat}}</span> 
                        <b class="color-main-red size-16">{{product.special_price | moneyFormat}}</b>
                    </h6>
                    <span class="weight-400 color-dark-gray-300 size-12">
                        {{ $t("product.valid_until.1") }}
                        {{ product.quantity_required_special_price }}
                        {{ $t("product.valid_until.2") }}
                    </span>
                </div>
                <div class="d-flex flex-row-reverse flex-md-row mt-auto align-items-end">
                    <product-quantity-input v-if="!confirmation" class="mt-auto" :productQuantity="getQuantity(product)" :product="product" 
                    @deleteProduct="$emit('deleteConfirm', product)" />

                    <div v-if="viewPort('sm', {max: true})" class="d-flex ms-md-auto size-17 me-auto me-xl-0 mb-0">
                        <span class="mobile-currency color-dark-main-navy me-1" style="vertical-align: top;">
                            {{showValueMoney()}}$ 
                        </span>
                        <span class="weight-600" :class="product.special_price ? 'color-main-red':'color-dark-main-navy'">
                            {{ product.special_price || getPriceFromCart(product) | moneyFormat(0, false) }}
                        </span>
                    </div>
                    <span v-if="viewPort({ from: 'sm', to: 'xl' })" class="me-auto me-md-0 ms-md-auto size-18">
                        <b class="color-main-navy">{{showValueMoney()}}$ </b> 
                        <b class="color-main-red ms-1">{{(getQuantity(product) * getPriceFromCart(product)) | moneyFormat(0, false)}}</b> 
                    </span>
                </div>
            </div>
        </div>
        <div v-if="viewPort('xl')" class="d-flex flex-column align-items-end">
            <div class="container-icons d-flex justify-content-between align-items-center mb-auto">
                <icon-share class="main-link" :size="24" color="var(--gray-1001)" @click.native="$emit('showShare', product)"/>
                <icon-cart v-if="!isCart && !confirmation" class="main-link" :size="30" color="var(--gray-1001)" @click.native="increment(product)" :class="[getQuantity(product) >= product.existence ? 'btn-disabled' : '']"/>
                <icon-trash v-if="isCart" class="main-link" :size="30" color="var(--gray-1001)" @click.native="$emit('deleteConfirm', product)" />
                <icon-favourite v-if="!confirmation"  class="main-link" :size="25" color="var(--gray-1001)" @click.native="addToWishList(product)" :class="{'fill-light-main-red': checkInFavorites(product)}"/>
            </div>
            <h6 class="subtitle-18 mb-0 color-dark-gray-300 text-end">
                {{$t("product.total")}}:
                <b class="color-main-navy ms-2">{{showValueMoney()}}$</b> 
                <b class="color-main-red">{{(getQuantity(product) * getPriceFromCart(product)) | moneyFormat(0, false)}}</b> 
            </h6>
        </div>
        <div v-if="viewPort('sm', { max: true }) && product.special_price" class="special-price-info size-12" :style="{'margin-left': distance}">
            <p class="color-dark-gray-200 mb-0" >
                {{ $t("product.valid_until.1") }}
                <span class="weight-600 color-dark-gray-400">
                    {{ product.quantity_required_special_price }}
                </span>
                {{ $t("product.valid_until.2") }}
            </p>
            <p class="special-price color-dark-gray-200 mb-0">
                {{ product.price| moneyFormat}}
            </p>
        </div>
        <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false" />
        
    </div>
</template>

<script>
import ImageProduct from './Image.vue'
import StarRating from './rating/StarRating.vue'
import ProductQuantityInput from './ProductQuantityInput.vue';
import RatingMenu from './rating/RatingMenu.vue';
import Ribbon from './Ribbon.vue';
// import DefaultColor from './DefaultColor.vue';
import QuickView from './quick-view/QuickView.vue';
import MobileOptionsMenu from './menu/MobileOptionsMenu.vue';

import IconCart from '../../../common/svg/iconCart.vue';
import IconTrash from '../../../common/svg/iconTrash.vue';
import IconFavourite from '../../../common/svg/iconFavourite.vue';
import IconShare from '../../../common/svg/iconShare.vue';
import IconEye from '../../../common/svg/iconEye.vue';
import IconDotMenu from '../../../common/svg/iconDotMenu.vue';
import { mapGetters } from 'vuex';


import { productMixin } from '../helpers/mixin/product-mixin';

export default {
    components: {
        ImageProduct,
        StarRating,
        ProductQuantityInput,
        RatingMenu,
        Ribbon,
        QuickView,
        IconCart,
        IconTrash,
        IconFavourite,
        IconShare,
        IconEye,
        // DefaultColor,
        IconDotMenu,
        MobileOptionsMenu
    },
    name: "ProductShortListView",
    props: {
        product: {
            type: Object,
            required: true
        },
        isCart: {
            type: Boolean
        },
        confirmation: {
            type: Boolean
        }
    },
    mixins: [productMixin],
    computed: {
        ...mapGetters({
            mobile: 'common/mobile',
        }),
        disabledClick(){
            if(this.isCart && this.mobile){
                return ''
            } else {
                return { name: 'product-detail', params: { id: this.product._id }}
            }
        },
        charactersLeft() {
            return 150 - this.note.length;
        },
        entityValidation(){
            return this.employee?.entity?.activated_employee
        },
    },
    data() {
        return {
            activeIndex: 0,
            addNote: false,
            note: '',
            savingNote: false,
            showRating: false,
            quickView: {value: false, product: null},
            mobileOptions: { id: '' },
            options: [
                { size: 22, icon: IconTrash, label: 'product.delete' },
                { size: 18, icon: IconFavourite, label: 'product.wishlist_add' },
                { size: 16, icon: IconShare, label: 'product.share', }
            ],
            distance: 0,
        }
    },
    methods: {
        showRatingFunction(boolean){
            this.showRating = boolean;
        },
        showQuickView({product, value}){
            if (this.isCart) {
                this.$emit('showQuickView', {product, value})
                return;
            }
            this.quickView.product = product;
            this.quickView.value = value;
        },
        shouldRenderItem(index) {
            return index === this.activeIndex || index === this.activeIndex - 1 || index === this.activeIndex + 1;
        },
        onSlideChange(ref) {
            this.activeIndex = this.$refs[ref].$swiper.activeIndex;
        },
    },
    async mounted() {
        // this.distance = this.$refs[`product-${this.product.codarticulo}`].clientWidth + 16 +'px';
    },
}
</script>

<style scoped lang="scss">

// .disabled-link {
//   /* Styles to visually disable the link */
//   color: #ccc; /* Change to a muted color */
//   text-decoration: none; /* Remove underline */
//   cursor: not-allowed; /* Change to "not-allowed" cursor */
//   pointer-events: none; /* Disable interactions */
// }

.custom-badge {
        color: white;
        padding: 0.5em 1em;
        border-radius: 0.25rem;
}
.product-deleted {
    opacity: 0.3;
}
.btn-disabled {
    cursor: no-drop !important;
    fill: var(--gray-50) !important;
}
.img-product-details {
    border-radius: 7px;
    overflow: hidden;
}
.vue-star-rating::v-deep div .vue-star-rating-rating-text {
    font-size: 14px;
    color: var(--dark-gray-200);
}
.container-column-2 {
    min-width: 200px;
}
.quantity-input-container::v-deep {
    border-radius: 50px;
    max-width: 100px;
}
.quantity-input-container::v-deep > div {
    height: 32px;
}
.container-product-short {
    height: 185px
}
.image__wrapper::v-deep > img {
    width: 185px !important;
    height: 185px;
}
.container-img-product:hover .image__wrapper::v-deep > img {
    transform: scale(1.2);
}
.container-img-product {
    border: 1px solid var(--gray-1001);
    height: 185px;
    width: 185px;
    position: relative;
    border-radius: 7px;
    transition: var(--transition-1);
    overflow: hidden;
    margin-right: 1.25rem;
}
.container-img-product:hover {
    overflow: hidden;
}
.dark-overlay {
    display: none;
    cursor: pointer;
    content: '';
    background: #0e1e3d24;
    position: absolute;
    width: 100%;
    z-index: 2;
    height: 100%;
    -webkit-animation: show-mask-data-v-2c065102 0.3s;
    animation: show-mask-data-v-2c065102 0.3s;
    margin-right: 1.25rem;
}
.container-img-product:hover .dark-overlay {
    width: 100%;
    height: 100%;
    display: block;
}
.container-img-product:hover .icon-eye{
    display: flex !important;
}
.icon-eye {
    background: var(--main-red);
    height: 40px;
    width: 40px;
    padding: 10px;
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    left: 37%;
    top: 37%;
    animation: show-eye 0.3s;
    cursor: pointer;
}
@keyframes show-mask {
    from {
        opacity: 0;
    }to {
        opacity: 1;
    }
}
@keyframes show-eye {
    from {
        opacity: 0;
        transform: translateY(20px);
    }to {
        opacity: 1;
        transform: translateY(0px);
    }
}
.show-rating {
    position: absolute;
    z-index: 2;
    width: 330px;
    left: -60%;
    top: 30px;
}
.rating-menu {
    animation: open-rating-menu 0.3s;
}
.show-rating::after {
    content: '';
    position: absolute;
    border-bottom: 12px solid white !important;
    border-left: 12px solid #f7000000 !important;
    border-right: 12px solid #ff000000 !important;
    top: -10px;
    left: 45%;
    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.3));
    z-index: -2;
    animation: open-rating-menu 1s;
}
.container-icons{
    gap: 18px;
}
.show-rating::before{
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 300px;
    height: 28px;
    background: transparent;
}
@keyframes open-rating-menu {
    from {
        opacity: 0;
        transform: scale(0.5);

    }to {
        opacity: 1;
        transform: scale(1);
    }
}
.special-price {
    text-decoration: line-through;
}
.dots-container {
    transform: translateX(6px);
    border-radius: 3.123rem;
    height: 30px;
    width: 30px;
}
.dots-container:hover, .dots-container.hovered {
    background-color: var(--light-gray-100);
}
.product-description {
    max-width: 220px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: var(--dark-main-navy) !important;
}
.detail-description > a {
    transition: var(--transition-1);
}
.detail-description:hover > a {
    color: var(--main-red) !important;
}
@media (min-width: 576px) {
    .dots-container {
        transform: unset;
    }
    .product-description {
        max-width: 315px;
        font-size: 17px;
        font-weight: 500;
    }
}
@media (min-width: 768px) {
    .product-description {
        max-width: 380px;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: nowrap !important;
    }
}
@media (min-width: 992px) {
    .product-description {
        max-width: 360px;
    }
}
@media (min-width: 1200px) {
    .product-description {
        max-width: 400px;
    }
}
@media (max-width: 768px) {
    .product-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .title-18 {
        font-size: 14px;
    }
    .subtitle-18 {
        font-size: 14px;
    }
    .container-product-short {
        height: auto
    }
    .image__wrapper::v-deep > img {
        width: 155px;
        height: 150px;
    }
    .container-img-product {
        height: 155px;
        width: 155px;
    }
}
@media (max-width: 576px) {
    .container-img-product {
        margin-right: 0;
    }
    .image__wrapper::v-deep > img {
        width: 120px !important;
        height: 120px;
        overflow: hidden;
    }
    .img-product-details {
        height: 120px;
    }
    .container-img-product {
        height: 120px;
        width: 120px;
    }
    .rating-info::v-deep .vue-star-rating-rating-text {
        margin-left: 1.2rem !important;
        padding-bottom: 2px;
    }
    .container-column-2 h6 {
        text-align: start;
        margin-top: 6px;
    }
    .product-description {
        font-size: 15px;
        font-weight: 400;
        max-width: 190px;
    }
    .product-title {
        margin-bottom: 8px;
    }
    .mobile-currency {
        font-size: 12px;
        font-weight: 400;
    }
    .quantity-input-container::v-deep div {
        width: 75px !important;
    }
    .icon-favourite {
        z-index: 1;
        top: 8px;
        right: 6px;
    }
    .favourite-photo-container {
        margin-right: 14px;
    }
}
</style>
