<template>
    <div class="dots-icon-container d-flex flex-column align-items-center align-content-center justify-content-center flex-shrink-0" :style="{width: size+'px', height: size+'px'}">
        <div v-for="dot in [1,2,3]" :key="dot" class="dot" :style="{backgroundColor: color}" />
    </div>
</template>

<script>
export default {
    name: 'iconDotMenu',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--dark-main-navy)'
        },
    },
};
</script>
<style scoped>
    .dot {
        width: 16%;
        height: 16%;
        border-radius: 3.12rem;
    }
    .dot:not(:last-child) {
        margin-bottom: 3px;
    }
</style>